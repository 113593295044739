<template>
  <div class="mx-3">
      <h2 class="px-5 py-5">{{ $t('Time Report') }}</h2>
    <v-row class="px-10 py-1">
      <v-col md="3">
        <v-autocomplete
        :label="$t('hr.time.emp-name')"
            :items="EmpItems"
            :loading="EmpLoading"
            color="#757575"
            item-text="name"
            item-value="id"
            v-model="filters.employee"
            @keypress="fetchAutoCompleteEmpName($event)" 
          >
          </v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-text-field :rules="requiredRule()"  :label="$t('hr.time.from-date')" type="date" 
                          v-model="filters.from">
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field :rules="requiredRule()"   :label="$t('hr.time.to-date')" type="date" 
                          v-model="filters.to">
        </v-text-field>
      </v-col>
      <v-col md="3">
      </v-col>
      <v-col md="6">
        <v-row>
            <v-checkbox
                  :label="$t('Just Extra')"
                  class="mx-4 mb-4"
                  v-model="filters.overtime"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  :label="$t('Just Leave')"
                  class="mx-4 mb-4"
                  v-model="filters.leave"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  :label="$t('Just Unjustified Leave')"
                  class="mx-4 mb-4"
                  v-model="filters.unjustified"
                  hide-details
                ></v-checkbox>
              </v-row>
      </v-col>
      <v-col md="6"></v-col>
      <v-col md="3" >
        <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
      </v-col>
      <v-col md="3" >
        <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
      </v-col>
      <v-col md="3" >
        <v-btn color="blue" dark block  @click="exportToExcel(filters)"
          >{{ $t('hr.time.export') }}
          </v-btn>
      </v-col>
    </v-row>

    <v-data-table :dark="$store.state.isDarkMode"
      :headers="headers"
      :loading="loading"
      :items="tableItems"
      class="elevation-1 px-10"
      hide-default-footer
      :items-per-page="10"
    >
 
    </v-data-table>
    <v-pagination
      v-if="!loading && tableItems.length"
      @input="getAll"
      v-model="page"
      :length="Math.ceil(total / 10)"
    ></v-pagination>
  </div>
</template>

<script>
import axios from "axios";
import i18n from '@/i18n';
import debounce from "../../../../../helpers/debounce";
import { requiredRule } from "@/helpers/validation-rules";

import { saveAs } from 'file-saver';


export default {
  components: {    },
  computed:{

  },
  data: () => ({
    
    EmpItems: [],
    EmpLoading: false,
    tableItems: [],
    loading: false,
    page: 1,
    total: 1,
    headers: [  
      { text: i18n.t("date"), value: "date" },
      { text: i18n.t('hr.time.emp-name'), value: "employee_name" },
      { text: i18n.t('Day Name'), value: "dayname" },
      { text: i18n.t('hr.time.timeIn'), value: "work_period.in_hour" },
      { text: i18n.t('hr.time.timeOut'), value: "work_period.out_hour" },
      { text: i18n.t('hr.time.Extra Hours'), value: "overtime" },
      { text: i18n.t('hr.time.Absent Hours'), value: "absent_period" },
      { text: i18n.t('Leave'), value: "leave" },
      { text: i18n.t('hr.calculation.shift'), value: "shift" },
     
    ],

    filters: {
      leave: false,
      employee: null,
      unjustified: false,
      from: null,
      to: null,
      overtime: false,
      
    },
  }),
  methods: {
    requiredRule() {
      return requiredRule
    },
   async exportToExcel(queryParams){
    const filterTemp = {};
    Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
       
       if(this.tableItems){
        try {
   
   const excel = await axios.get(`hr/report/work-day/export`, {
     params: {
       ...filterTemp,
       
     },
     responseType: 'blob'
   });
   saveAs(excel.data)
   

 } catch (err) {
   console.log('err', err);
 } finally {
   
 }
   
       }
    },
 
    clearFilter() {
      this.filters.employee = undefined;
      this.filters.overtime = undefined;
      this.filters.unjustified = undefined;
      this.filters.from = undefined;
      this.filters.to = undefined;
      this.filters.leave = undefined;
      this.page = 1;
      this.getAll();  
    },
   
 
    async getAll(page) {
     if(this.filters.from && this.filters.to){
      try {
        
        if (!page) this.page = 1;
        const filterTemp = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
        this.loading = true;
        
        const res = await axios.get("hr/report/work-day", {
          params: {
            size: 10,
            page: this.page,
            ...filterTemp,
          },
        });
        const arr= Array.from(res.data.data)
        if(Array.isArray(arr))
        {
          console.log(arr);
          this.tableItems = arr.map((el) => {
            
            return {
              ...el,
              
            };
          });
        }
          console.log(res.data.data);
        
        this.total = res.data.total;
      } finally {
        this.loading = false;
      }
     }
    },
  
  
  async fetchAutoCompleteEmpName(searchQuery) {
    debounce(async () => {
      let searchValue = searchQuery.target.value;
      this.EmpLoading = true;
      if (!searchValue) return;
      try {
        const searchResult = await axios.get(
          "/hr/employee/auto-complete",
          {
            params: {
              name: searchValue,
            },
          }
        );
        this.EmpItems = searchResult.data.data;
      } catch (err) {
        console.log("err", err);
      } finally {
        this.EmpLoading = false;
      }
    }, 500)();
  },
  
  },
  created() {
   
  },
};
</script>

<style></style>
